import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import Seo from "../components/common/SEO";
import "../styles/Terms.scss";

const content = [
  {
    id: 1,
    metaTitle: "Terms and Conditions - Invocom",
    metaDescription:
      "Invocom terms & conditions - All terms refer to acceptance, offers, and consideration. We employee the use of cookies in agreement with our privacy policy.",
  },
];

export default function terms() {
  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="terms-and-conditions/"
          />
        </div>
      ))}
      <Container>
        <Box className="pageTerms-wrapper">
          <Box className="terms-head">
            <Typography variant="h1">Terms and Conditions</Typography>
            <Typography variantMapping="p">
              Welcome to InvoCom!
              <br />
              <br />
              These terms and conditions outline the rules and regulations for
              the use of InvoCom's Website, located at invocom.io.
              <br />
              <br />
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use InvoCom if you do not agree to
              take all of the terms and conditions stated on this page.
              <br />
              <br />
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice, and all Agreements:
              "Client", "You" and "Your" refers to you, the person who logs on
              to this website and is compliant with the Company’s terms and
              conditions. "The Company", "Ourselves", "We", "Our" and "Us",
              refers to our Company. "Party", "Parties", or "Us", refers to both
              the Client and ourselves. All terms refer to the offer,
              acceptance, and consideration of payment necessary to undertake
              the process of our assistance to the Client in the most
              appropriate manner for the express purpose of meeting the Client’s
              needs in respect of the provision of the Company’s stated
              services. Any use of the above terminology or other words in the
              singular, plural, capitalization, and/or he/she or they, are taken
              as interchangeable and therefore as referring to the same.
            </Typography>
          </Box>
          <Box className="sub-content">
            <Typography variant="h2">Cookies</Typography>
            <Typography variantMapping="p">
              We employ the use of cookies. By accessing InvoCom, you agreed to
              use cookies in agreement with InvoCom's Privacy Policy.
              <br />
              Most interactive websites use cookies to let us retrieve the
              user’s details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies.
            </Typography>
          </Box>
          <Box className="sub-content">
            <Typography variant="h2">License</Typography>
            <Typography variantMapping="p">
              Unless otherwise stated, InvoCom and/or its licensors own the
              intellectual property rights for all material on InvoCom. All
              intellectual property rights are reserved. You may access this
              from InvoCom for your own personal use subject to restrictions set
              in these terms and conditions.
              <br />
              <br />
              <Typography className="list-Line">You must not:</Typography>
              <br />
              <Box className="listBox">
                <ul>
                  <li>Republish material from InvoCom</li>
                  <li>Sell, rent, or sub-license material from InvoCom</li>
                  <li>Reproduce, duplicate or copy material from InvoCom</li>
                  <li>Redistribute content from InvoCom</li>
                </ul>
              </Box>
              <br />
              This Agreement shall begin on the date hereof. Parts of this
              website offer an opportunity for users to post and exchange
              opinions and information in certain areas of the website. InvoCom
              does not filter, edit, publish or review Comments prior to their
              presence on the website. Comments do not reflect the views and
              opinions of InvoCom, its agents, and/or affiliates. Comments
              reflect the views and opinions of the person who posts their views
              and opinions. To the extent permitted by applicable laws, InvoCom
              shall not be liable for the Comments or for any liability,
              damages, or expenses caused and/or suffered as a result of any use
              of and/or posting of and/or appearance of the Comments on this
              website.
              <br />
              InvoCom reserves the right to monitor all Comments and to remove
              any Comments which can be considered inappropriate, offensive, or
              causes a breach of these Terms and Conditions.
              <br />
              <br />
              <Typography className="list-Line">
                You warrant and represent that:
              </Typography>
              <br />
              <Box className="listBox">
                <ul>
                  <li>
                    You are entitled to post the Comments on our website and
                    have all necessary licenses and consents to do so;
                  </li>
                  <li>
                    The Comments do not invade any intellectual property right,
                    including without limitation copyright, patent, or trademark
                    of any third party;
                  </li>
                  <li>
                    The Comments do not contain any defamatory, libelous,
                    offensive, indecent or otherwise unlawful material which is
                    an invasion of privacy
                  </li>
                  <li>
                    The Comments will not be used to solicit or promote business
                    or custom or present commercial activities or unlawful
                    activity.
                  </li>
                </ul>
              </Box>
              <br />
              You hereby grant InvoCom a non-exclusive license to use,
              reproduce, edit and authorize others to use, reproduce and edit
              any of your Comments in any and all forms, formats or media.
            </Typography>
          </Box>
          {/* ____________ */}
          <Box className="sub-content">
            <Typography variant="h2">Hyperlinking to our Content</Typography>
            <Typography variantMapping="p">
              <Typography className="list-Line">
                The following organizations may link to our Website without
                prior written approval:
              </Typography>
              <br />
              <Box className="listBox">
                <ul>
                  <li>Government agencies;</li>
                  <li>Search engines;</li>
                  <li>News organizations;</li>
                  <li>
                    Online directory distributors may link to our Website in the
                    same manner as they hyperlink to the Websites of other
                    listed businesses; and
                  </li>
                  <li>
                    System-wide Accredited Businesses except soliciting
                    non-profit organizations, charity shopping malls, and
                    charity fundraising groups which may not hyperlink to our
                    Website.
                  </li>
                </ul>
              </Box>
              <br />
              These organizations may link to our home page, to publications, or
              to other Website information so long as the link: (a) is not in
              any way deceptive; (b) does not falsely imply sponsorship,
              endorsement, or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking
              party’s site.
              <br />
              <br />
              <Typography className="list-Line">
                We may consider and approve other link requests from the
                following types of organizations:
              </Typography>
              <br />
              <Box className="listBox">
                <ul>
                  <li>
                    commonly-known consumer and/or business information sources;
                  </li>
                  <li>dot.com community sites;</li>
                  <li>associations or other groups representing charities;</li>
                  <li>online directory distributors;</li>
                  <li>internet portals;</li>
                  <li>accounting, law, and consulting firms; and</li>
                  <li>educational institutions and trade associations.</li>
                </ul>
              </Box>
              <br />
              We will approve link requests from these organizations if we
              decide that: (a) the link would not make us look unfavorably to
              ourselves or to our accredited businesses; (b) the organization
              does not have any negative records with us; (c) the benefit to us
              from the visibility of the hyperlink compensates the absence of
              InvoCom, and (d) the link is in the context of general resource
              information.
              <br />
              These organizations may link to our home page so long as the link:
              (a) is not in any way deceptive; (b) does not falsely imply
              sponsorship, endorsement, or approval of the linking party and its
              products or services; and (c) fits within the context of the
              linking party’s site.
              <br />
              If you are one of the organizations listed in paragraph 2 above
              and are interested in linking to our website, you must inform us
              by sending an email to InvoCom. Please include your name, your
              organization name, and contact information as well as the URL of
              your site, a list of any URLs from which you intend to link to our
              Website, and a list of the URLs on our site to which you would
              like to link. Wait 2-3 weeks for a response.
              <br />
              <br />
              <Typography className="list-Line">
                Approved organizations may hyperlink to our Website as follows:
              </Typography>
              <br />
              <Box className="listBox">
                <ul>
                  <li>By use of our corporate name; or</li>
                  <li>
                    By use of the uniform resource locator being linked to; or
                  </li>
                  <li>
                    By use of any other description of our Website being linked
                    to that makes sense within the context and format of content
                    on the linking party’s site.
                  </li>
                </ul>
              </Box>
              <br />
              No use of InvoCom's logo or other artwork will be allowed for
              linking absent a trademark license agreement.
            </Typography>
          </Box>
          <Box className="sub-content">
            <Typography variant="h2">iFrames</Typography>
            <Typography variantMapping="p">
              Without prior approval and written permission, you may not create
              frames around our Web Pages that alter in any way the visual
              presentation or appearance of our Website.
            </Typography>
          </Box>
          <Box className="sub-content">
            <Typography variant="h2">Content Liability</Typography>
            <Typography variantMapping="p">
              We shall not be held responsible for any content that appears on
              your Website. You agree to protect and defend us against all
              claims that are rising on your Website. No link(s) should appear
              on any Website that may be interpreted as libelous, obscene or
              criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </Typography>
          </Box>
          <Box className="sub-content">
            <Typography variant="h2">Your Privacy</Typography>
            <Typography variantMapping="p">
              Please read Privacy Policy
            </Typography>
          </Box>
          <Box className="sub-content">
            <Typography variant="h2">Reservation of Rights</Typography>
            <Typography variantMapping="p">
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve to immediately remove
              all links to our Website upon request. We also reserve the right
              to amend these terms and conditions and its linking policy at any
              time. By continuously linking to our Website, you agree to be
              bound to and follow these linking terms and conditions.
            </Typography>
          </Box>
          <Box className="sub-content">
            <Typography variant="h2">
              Removal of links from our website
            </Typography>
            <Typography variantMapping="p">
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us at any moment. We
              will consider requests to remove links but we are not obligated to
              or so or to respond to you directly.
              <br />
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
